"use client";

import { api } from "@/client/Api";
import { Client } from "@/client/Client";
import { PaginatedWrapper, PaginatorInitParams } from "@/client/PaginatedWrapper";
import { requestsStorage } from "@/client/storage/ReqStrorage";
import {
  BasicAiResponseDTO,
  ChannelFullDTO,
  MessageDTO,
  RepeatStartDTO,
  SearchChannelDTO,
  SearchRequestFullDTO, SearchRequestShortDTO,
  SearchStartDTO,
  StartResult,
} from "@/client/types/api.types";
import { from, map, Observable } from "rxjs";


class State {

  private readonly client = new Client();


  async init() {

  }

  async startSearch(params: SearchStartDTO): StartResult {
    return this.client.startSearch(params);
  }

  async repeatSearch(params: RepeatStartDTO): StartResult {
    return this.client.repeatSearch(params);
  }


  getChannel$(hash: string): Observable<ChannelFullDTO> {
    return from(this.client.getChannel(hash));
  }


  getRequestDetail$(hash: string): Observable<SearchRequestFullDTO> {
    return requestsStorage.get$(hash);
  }

  getLastMessages$(hash: string): Observable<MessageDTO[]> {
    return from(api.getMessages(hash)).pipe(
      map(res => res.items),
    );
  }

  getChannelAIInfo$(hash: string): Observable<BasicAiResponseDTO> {
    return from(api.getAiBasic(hash)).pipe(
      map(res => res),
    );
  }


  getResultsPaginator(hash: string, init?: PaginatorInitParams): PaginatedWrapper<SearchChannelDTO> {
    return new PaginatedWrapper<SearchChannelDTO>((p) => {
      return api.getSearchResults(hash, p);
    }, init);
  }


  getSearchHistoryPaginator(init?: PaginatorInitParams) {
    return new PaginatedWrapper<SearchRequestShortDTO>((p) => {
      return api.getSearchHistory(p);
    }, init);
  }


}


export const state = new State();
state.init().then(() => {
  console.log("Global state holder initialized");
});