"use client";

import PageSection from "@/components/page/PageSection";
import React from "react";


export default function AboutBlock() {


  return (
    <PageSection title="Как это работает">
      <p>
        Наш инструмент использует алгоритмы Telegram для поиска каналов,
        схожих по тематике и аудитории. Процесс начинается с задания базового канала:
        вы указываете отправную точку для поиска, и алгоритм автоматически подбирает список каналов,
        которые Telegram считает релевантными.
      </p>
      <p>
        После этого скрипт не останавливается на достигнутом — для каждого найденного канала
        запускается новый поиск по аналогичной схеме. Так формируется расширенная сеть рекомендаций,
        охватывающая все каналы, связанные с исходным запросом.
        На выходе вы получаете обширную базу каналов, связанных тематически и по интересам аудитории.
      </p>
      <p>
        Далее все результаты собираются в единую таблицу, где автоматически удаляются дублирующиеся записи.
        В итоговой таблице вы увидите количество подписчиков, описание и основную информацию по каждому каналу.
        Такой подход позволяет вам получить комплексное представление о тематическом ландшафте Telegram
        и находить каналы, которые действительно соответствуют вашим задачам.
      </p>
    </PageSection>
  );
}
