import { Heading } from "@radix-ui/themes";
// @ts-expect-error suppress import warning (work OK)
import { headingPropDefs } from "@radix-ui/themes/dist/cjs/components/heading.props";
import React from "react";

type PropsType = Readonly<{
  text?: string | React.ReactNode,
  as?: typeof headingPropDefs.as
}>

export default function PageHeading({
  text,
  as,
}: PropsType) {
  return text ? <Heading mb="3" as={as}>{text}</Heading> : [];
}
