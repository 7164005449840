import * as Form from "@radix-ui/react-form";
import { Flex, TextField } from "@radix-ui/themes";
import React from "react";


type InputTypes = 'date' | 'datetime-local' | 'email' | 'hidden' | 'month' | 'number' | 'password' | 'search' | 'tel' | 'text' | 'time' | 'url' | 'week';

type TypeProps = Readonly<{
  name: string;
  label: string;
  defaultValue?: string|number;
  placeholder?: string;
  type?: InputTypes;
  required?: boolean | undefined;
  missingMessage?: string;
  invalidMessage?: string;
}>;

export default function FormTextField({
  name,
  label,
  type,
  required,
  defaultValue,
  placeholder,
  missingMessage,
  invalidMessage,
}: TypeProps) {
  return (
    <Form.Field className="FormField" name={name}>
      <Flex direction="column">
        <Form.Label className="FormLabel">{label}</Form.Label>
        <Form.Message className="FormMessage" match="valueMissing">
          {missingMessage}
        </Form.Message>
        <Form.Message className="FormMessage" match="typeMismatch">
          {invalidMessage}
        </Form.Message>
      </Flex>
      <Form.Control asChild>
        <TextField.Root defaultValue={defaultValue} placeholder={placeholder} type={type} required={required}/>
      </Form.Control>
    </Form.Field>
  );
}
