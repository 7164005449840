import { Box } from "@radix-ui/themes";
import React from "react";

type PropsType = Readonly<{
  children?: React.ReactNode;
}>

export default function PageBlock({
  children,
}: PropsType) {
  return (
    <Box className="PageBlock" p="4" style={{ borderRadius: "var(--radius-4)" }}>
      {children}
    </Box>
  );
}
