import PageBlock from "@/components/page/PageBlock";
import PageHeading from "@/components/page/PageHeading";
import { Section } from "@radix-ui/themes";
// @ts-expect-error suppress import warning (work OK)
import { headingPropDefs } from "@radix-ui/themes/dist/cjs/components/heading.props";
import React from "react";

type PropsType = Readonly<{
  title?: string | React.ReactElement,
  titleType?: typeof headingPropDefs.as
  children?: React.ReactNode;
}>


export default function PageSection({
  children,
  title,
  titleType,
}: PropsType) {
  return (
    <Section size="2">
      <PageHeading text={title} as={titleType}/>
      <PageBlock>{children}</PageBlock>
    </Section>
  );
}
