import { api } from "@/client/Api";
import { SearchRequestFullDTO } from "@/client/types/api.types";
import {
  connectable,
  Connectable,
  from,
  Observable,
  of,
  ReplaySubject,
  Subject,
  switchMap,
  takeWhile,
  timer,
} from "rxjs";


type StateSubject = Subject<SearchRequestFullDTO>;

export class ReqStorage {

  private subj = new Map<string, StateSubject>();
  private pub = new Map<string, Connectable<SearchRequestFullDTO>>();

  private getSubject(hash: string): StateSubject {
    if (!this.subj.has(hash)) {
      const newSbj = new ReplaySubject<SearchRequestFullDTO>();
      this.subj.set(hash, newSbj);
    }
    return this.subj.get(hash) as StateSubject;
  }

  private getPub(hash: string, initValue?: SearchRequestFullDTO): Connectable<SearchRequestFullDTO> {
    if (!this.pub.has(hash)) {
      const init = initValue ? of(initValue) : from(api.getSearch({ hash }));
      const pub = connectable(init, {
        connector: () => this.getSubject(hash),
        resetOnDisconnect: false,
      });

      pub.connect();
      this.pub.set(hash, pub);
    }
    return this.pub.get(hash) as  Connectable<SearchRequestFullDTO>;
  }

  get$(hash: string): Observable<SearchRequestFullDTO> {
    return this.loadUntilDone$(hash);
  }


  loadUntilDone$(hash: string): Observable<SearchRequestFullDTO> {
    return timer(0, 1000).pipe(
      switchMap( () => from(api.getSearch({ hash }))),
      takeWhile(data => !data.complete, true),
    );
  }



  save$(request: SearchRequestFullDTO | Promise<SearchRequestFullDTO>): Observable<SearchRequestFullDTO> {
    if (request instanceof Promise) {
      return from(request).pipe(
        switchMap((startDto) => this._save(startDto)),
      );
    } else {
      return this._save(request);
    }
  }

  private _save(request: SearchRequestFullDTO): Observable<SearchRequestFullDTO> {
    const hash = request.hash;
    const subj = this.subj.get(hash);
    subj?.next(request);
    return this.get$(hash);
  }




}


export const requestsStorage = new ReqStorage();