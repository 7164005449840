import { api } from "@/client/Api";
import { ChannelFullDTO, RepeatStartDTO, SearchStartDTO, StartResult } from "@/client/types/api.types";


export class Client {


  async startSearch(params: SearchStartDTO): StartResult {
    const data = await api.startSearch(params);
    // requestsStorage.save$(data).then();
    return data;
  }

  async repeatSearch(params: RepeatStartDTO): StartResult {
    const data = await api.repeatSearch(params);
    // requestsStorage.save$(resp.data).then();
    return data;
  }

  async getChannel(hash: string): Promise<ChannelFullDTO> {
    const data = await api.getChannelInfo(hash);
    // requestsStorage.save$(resp.data).then();
    return data;
  }


}
