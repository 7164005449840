"use client";

import AboutBlock from "@/blocks/AboutBlock";
import MainPageSearchBlock from "@/blocks/MainPageSearch";
import React from "react";


export default function Home() {
  return (<>
    <MainPageSearchBlock />
    <AboutBlock />
  </>);
}
