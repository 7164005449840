"use client";

import { StartResultDTO } from "@/client/types/api.types";
import PageSection from "@/components/page/PageSection";
import SearchComponent from "@/components/search/Search";
import { useRouter } from "next/navigation";
import React from "react";


export default function MainPageSearch() {
  const router = useRouter()

  const gotoReqPage = (started: StartResultDTO) => {
    router.push(`/search/${started.hash}`)
  }

  return (
    <PageSection title="Начать новый поиск">
      <SearchComponent onSuccess={gotoReqPage}/>
    </PageSection>
  );
}
