"use client";

import { SearchStartDTO } from "@/client/types/api.types";
import FormTextField from "@/components/forms/FormTextField";
import { SEARCH } from "@/constants";
import "@/styles/form.css";
import { FormHandler } from "@/types";
import * as Form from "@radix-ui/react-form";
import { MagnifyingGlassIcon } from "@radix-ui/react-icons";
import { Button, Flex } from "@radix-ui/themes";
import React, { useState } from "react";
import { isNumber, isString } from "remeda";


type TypeProps = Readonly<{
  // children?: React.ReactNode;
  action: FormHandler<SearchStartDTO>,
  locked?: boolean,
  defaultValue?: Partial<SearchStartDTO>,
}>;

const defaultSearch: SearchStartDTO = {
  query: SEARCH.DEFAULTS.QUERY,
  depth: SEARCH.DEFAULTS.DEPTH,
  limit: SEARCH.DEFAULTS.WIDTH,
};

const noop = () => {
};

function extractData(formData: FormData, defaultValue?: Partial<SearchStartDTO>): SearchStartDTO {
  const rawQuery = formData.get("query");
  const rawDepth = formData.get("depth");
  const rawLimit = formData.get("limit");
  const data: SearchStartDTO = {
    query: isString(rawQuery) && rawQuery || "",
    depth: isNumber(rawDepth) && rawDepth || isString(rawDepth) && parseInt(rawDepth) || defaultValue?.depth || SEARCH.DEFAULTS.DEPTH,
    limit: isNumber(rawLimit) && rawLimit || isString(rawLimit) && parseInt(rawLimit) || defaultValue?.limit || SEARCH.DEFAULTS.WIDTH,
  };
  return data as SearchStartDTO;
}


function useLockState(locked: boolean | undefined) {
  const [stateFlag, stateSet] = useState(false);
  if (locked === undefined) {
    return { lockedFlag: stateFlag, setLocked: stateSet };
  } else {
    return { lockedFlag: locked, setLocked: noop };
  }
}

export default function SearchForm({
  action,
  locked,
  defaultValue,
}: TypeProps) {

  const { setLocked, lockedFlag } = useLockState(locked);
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const formData = new FormData(e.target as HTMLFormElement);
    const data = extractData(formData, defaultValue);
    setLocked(true);
    action(data)
      .finally(() => {
        setLocked(false);
      });
  };


  return (
    <Form.Root className="FormRoot" onSubmit={onSubmit}>
      <Flex direction="column" gap="3">
        <FormTextField
          name="query"
          label="Имя канала"
          placeholder="@vcnews"
          required
          missingMessage="Запрос не должен быть пустым"
          invalidMessage="Некорректны запрос"
          defaultValue={defaultValue?.query}
        />

        <Flex direction="row" gap="3">

          <FormTextField
            name="depth"
            label="Глубина"
            type="number"
            key={'depth'}
            defaultValue={defaultValue?.depth || defaultSearch.depth}
          />

          <FormTextField
            name="limit"
            label="Ширина"
            type="number"
            key={'limit'}
            defaultValue={defaultValue?.limit || defaultSearch.limit}
          />

        </Flex>

        <Flex direction="row" gap="3">
          <Form.Submit asChild>
            <Button disabled={lockedFlag}>
              <MagnifyingGlassIcon /> Начать поиск
            </Button>
          </Form.Submit>
        </Flex>

      </Flex>


    </Form.Root>
  );
}
