"use client";

import { state } from "@/client/State";
import { SearchStartDTO, StartResultDTO } from "@/client/types/api.types";
import SearchForm from "@/forms/SearchForm";
import { FormHandler } from "@/types";
import React from "react";


type TypeProps = Readonly<{
  onSuccess?: (request: StartResultDTO) => void,
  onError?: (error: unknown) => void,
  defaultValue?: Partial<SearchStartDTO>,
}>;


export default function SearchComponent({
  onSuccess,
  onError,
  defaultValue,
}: TypeProps) {

  const searchHandler: FormHandler<SearchStartDTO> = async (data) => {
    // console.log('data', data);
    try {
      const started = await state.startSearch(data);
      if (onSuccess) onSuccess(started);
    } catch (e){
      if (onError) onError(e);
    }
  };


  return (
    <SearchForm
      action={searchHandler}
      defaultValue={defaultValue}
    />
  );
};
