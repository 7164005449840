import { PaginatedList, PaginationRequestDTO, StaticPagination } from "@/client/types/api.types";
import { IPaginatedSource } from "@/client/types/IPaginatedSource";
import {
  BehaviorSubject,
  catchError,
  combineLatestWith,
  EMPTY,
  map,
  Observable,
  shareReplay,
  startWith,
  Subject,
  switchMap,
  tap,
  throttleTime,
} from "rxjs";

export type PaginatorInitParams = Partial<PaginationRequestDTO>;


export class PaginatedWrapper<T> implements IPaginatedSource<T> {

  private readonly _loading$ = new BehaviorSubject<boolean>(false);

  private readonly _ping$ = new Subject<void>();
  private readonly _page$ = new BehaviorSubject<number>(1);
  private readonly _size$ = new BehaviorSubject<number | undefined>(undefined);

  private readonly _requestParams$: Observable<PaginationRequestDTO> = this._page$.pipe(
    combineLatestWith(
      this._size$,
      this._ping$.pipe(startWith(undefined)),
    ),
    map(([page, size]) => ({ page, size })),
  );

  private readonly _debouncedParams$: Observable<PaginationRequestDTO> = this._requestParams$.pipe(
    map((params) => params),
    throttleTime(1000, undefined, { leading: true, trailing: true }),
  );

  private readonly _rawResults$: Observable<PaginatedList<T, StaticPagination>> = this._debouncedParams$.pipe(
    tap(() => this._loading$.next(true)),
    switchMap((params) => this.loadFn(params)),
    catchError(err => {
      console.log("load error", err);
      return EMPTY;
    }),
    tap(() => this._loading$.next(false)),
    shareReplay({ bufferSize: 1, refCount: true }),
  );


  constructor(
    private readonly loadFn: (p: PaginationRequestDTO) => Promise<PaginatedList<T, StaticPagination>>,
    init?: PaginatorInitParams,
  ) {
    if (init && init.page) this._page$.next(init.page);
    if (init && init.size) this._size$.next(init.size);
  }


// ===========================================================================================
  //             PUBLIC
  // ===========================================================================================

  public readonly items$: Observable<T[]> = this._rawResults$.pipe(
    map(resp => resp.items),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  public readonly page$: Observable<number> = this._rawResults$.pipe(
    map(resp => resp.pagination.page),
    shareReplay({ bufferSize: 1, refCount: true }),
  );
  public readonly pages$: Observable<number> = this._rawResults$.pipe(
    map(resp => resp.pagination.pages),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  public readonly total$: Observable<number> = this._rawResults$.pipe(
    map(resp => resp.pagination.total),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  public readonly size$: Observable<number> = this._rawResults$.pipe(
    map(resp => resp.pagination.size),
    shareReplay({ bufferSize: 1, refCount: true }),
  );


  setPage(value: number) {
    this._page$.next(value);
  }

  refresh(): void {
    this._ping$.next();
  }


}